import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from "@angular/router";
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/modules/wrcommon/services/translation.service';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { CallUsRequestService } from '../../services/call-us-request.service';
import { ResponsiveService } from '../../services/responsive.service';
import { TenantService } from '../../services/tenant.service';
import { ChargeToAccountLabelStoreService } from '../../services/charge-to-account-label-store.service';
import { Constants } from 'src/app/modules/wrcommon/classes/constants';
import { WebRequestConfig } from 'src/app/modules/wrcommon/classes/config';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { virtualKeyboardDetector } from 'src/app/modules/wrcommon/classes/virtual-keyboard-detector';
import { brandVariables } from 'src/brand-variables';
import { WorkOrderRequestService } from 'src/app/modules/shared-request/services/work-order-request.service';
var LayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LayoutComponent, _super);
    function LayoutComponent(userService, router, titleService, translationService, chargeToAccountLabelStoreService, tenantService, responsiveService, callUsRequestService, workOrderRequestService) {
        var _this = _super.call(this) || this;
        _this.userService = userService;
        _this.router = router;
        _this.titleService = titleService;
        _this.translationService = translationService;
        _this.chargeToAccountLabelStoreService = chargeToAccountLabelStoreService;
        _this.tenantService = tenantService;
        _this.responsiveService = responsiveService;
        _this.callUsRequestService = callUsRequestService;
        _this.workOrderRequestService = workOrderRequestService;
        _this.displayLeftNavTree = true;
        _this.visible = false;
        _this.phoneSlideoutActive = false;
        _this.virtualKeyboardVisible = false;
        _this.localBrandVariables = brandVariables;
        _this.ssoLogoutUrl = WebRequestConfig.baseUrlRealSuite + "User/logout?redirectUrl=" + WebRequestConfig.baseUrlWebAssist + "login?logout=true";
        _this.isSSOFederated = false;
        _this.hasUserName = false;
        return _this;
    }
    Object.defineProperty(LayoutComponent.prototype, "isAuthenticated", {
        get: function () {
            return this.userService.isAuthenticated();
        },
        enumerable: true,
        configurable: true
    });
    LayoutComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isDataReady = false;
                this.tenantService.state$
                    .pipe(takeUntil(this.destroyed))
                    .subscribe(function (tenant) {
                    _this.isSSOFederated = tenant && tenant.ssoFederated;
                    _this.hasUserName = tenant && !!tenant.LoginName;
                });
                this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), takeUntil(this.destroyed)).subscribe(function (event) {
                    var url = event.urlAfterRedirects;
                    var parts = url.split('/');
                    var path = parts.pop() || parts.pop(); // handle potential trailing slash
                    path = path.split('?')[0];
                    var prevPath = parts.pop();
                    var route = _this.router.config.find(function (item) { return item.path === path; });
                    if (!route) {
                        var childRoutes = [].concat.apply([], _this.router.config.filter(function (item) { return !!item.children; }).map(function (item) { return item.children; }));
                        route = childRoutes.find(function (item) { return item.path === path; });
                        if (!route) {
                            var parentRoute = childRoutes.find(function (item) { return item.path === prevPath; });
                            if (parentRoute) {
                                if (parentRoute._loadedConfig && parentRoute._loadedConfig.routes && parentRoute._loadedConfig.routes.length > 0) {
                                    route = parentRoute._loadedConfig.routes.find(function (item) { return item.path === path; });
                                }
                                else {
                                    route = parentRoute;
                                }
                            }
                        }
                    }
                    if (!route) {
                        var parentRoute = _this.router.config.find(function (item) { return item.path === prevPath; });
                        if (parentRoute) {
                            if (!route && parentRoute._loadedConfig && parentRoute._loadedConfig.routes && parentRoute._loadedConfig.routes.length > 0) {
                                route = parentRoute._loadedConfig.routes.find(function (item) { return item.path === path; });
                                if (!route && parentRoute._loadedConfig.routes[0].children) {
                                    route = parentRoute._loadedConfig.routes[0].children.find(function (item) { return item.path === path; });
                                }
                                if (!route || !route.data || !route.data.breadcrumb) {
                                    route = parentRoute;
                                }
                            }
                        }
                        _this.isDataReady = false;
                        _this.getModuleOptions();
                    }
                    var title = 'shared.WebRequest';
                    if (!!route && route.data != null) {
                        if (route.data.breadcrumb != null) {
                            title = route.data.breadcrumb;
                        }
                        else if (route.data.title != null) {
                            title = route.data.title;
                        }
                    }
                    _this.setHeaderTitle(title);
                    // Start listening for virtual keyboard (dis)appearences
                    virtualKeyboardDetector.init({ recentlyFocusedTimeoutDuration: 3000 });
                    // Handle the appearing of the virtual keyboard
                    virtualKeyboardDetector.on('virtualKeyboardVisible', function () {
                        _this.virtualKeyboardVisible = true;
                    });
                    // Handle the disappearing of the virtual keyboard
                    virtualKeyboardDetector.on('virtualKeyboardHidden', function () {
                        _this.virtualKeyboardVisible = false;
                    });
                });
                //this.responsiveService.state$
                //  .pipe(takeUntil(this.destroyed))
                //  .subscribe(isMobile => {
                //  if (isMobile) {
                //    console.log('Mobile device detected')
                //  }
                //  else {
                //    console.log('Desktop detected')
                //  }
                //});
                this.onResize();
                return [2 /*return*/];
            });
        });
    };
    LayoutComponent.prototype.setHeaderTitle = function (title) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var webRequest, _a, headerTitle;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log(title);
                        return [4 /*yield*/, this.translationService.translate("shared.WebRequest")];
                    case 1:
                        webRequest = _b.sent();
                        if (webRequest === 'BGIS Assist') {
                            webRequest = this.localBrandVariables.appTitle;
                        }
                        if (!(title === 'shared.WebRequest')) return [3 /*break*/, 2];
                        this.title = this.localBrandVariables.appTitle;
                        return [3 /*break*/, 4];
                    case 2:
                        _a = this;
                        return [4 /*yield*/, this.translationService.translate(title)];
                    case 3:
                        _a.title = _b.sent();
                        _b.label = 4;
                    case 4:
                        // update charge To Account label after waiting for 2 sec
                        if (title === Constants.ChargeToAccountTitle) {
                            this.chargeToAccountLabelStoreService.state$
                                .pipe(takeUntil(this.destroyed))
                                .subscribe(function (label) {
                                if (!!label) {
                                    _this.title = _this.title.replace(new RegExp(Constants.ChargeToAccountKeyword, 'g'), label);
                                    var headerTitle_1 = webRequest + " - " + _this.title;
                                    _this.titleService.setTitle(headerTitle_1);
                                }
                            });
                        }
                        headerTitle = webRequest == this.title ? this.title : webRequest + " - " + this.title;
                        this.titleService.setTitle(headerTitle);
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(LayoutComponent.prototype, "currentUser$", {
        get: function () {
            return this.userService.getData();
        },
        enumerable: true,
        configurable: true
    });
    LayoutComponent.prototype.onResize = function () {
        this.responsiveService.checkWidth();
    };
    LayoutComponent.prototype.getModuleOptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.userService.getClientId()];
                    case 1:
                        _a.clientId = _b.sent();
                        this.workOrderRequestService.getModuleOptions(this.clientId).subscribe(function (res) {
                            _this.moduleOptions = res;
                            _this.isDataReady = true;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return LayoutComponent;
}(DestroyableComponentBase));
export { LayoutComponent };
