<div
    *ngIf="isAuthenticated | async"
    class="bottombar"
    [class.expanded]="visible">
    <div class="main-bar">
        <a  class="newRequest"
            routerLink="/newrequest"
            routerLinkActive="active">
            <svg-icon
                role="presentation"
                src="assets/images/icons/menu-icons/plus.svg"></svg-icon>
      <span class="link-label">{{'SideNav.NewRequest' | translate}}</span>
    </a>
    <a href="javascript: void(0)"
       (click)="callUsRequestService.openCallUsContentAlert()">
      <svg-icon role="presentation"
                src="assets/images/icons/menu-icons/warning.svg"></svg-icon>
      <span class="link-label">
        {{'alert.EmergencyRequest' | translate}}
      </span>
    </a>
    <a routerLink="/myrequests/activerequests"
       routerLinkActive="active">
      <svg-icon role="presentation"
                src="assets/images/icons/menu-icons/wrench.svg"></svg-icon>
      <span class="link-label">{{'SideNav.MyRequests' | translate}}</span>
    </a>
    <a class="icon-more"
       href="javascript: void(0)"
       (click)="visible = !visible">
      <svg-icon [class.active]="visible"
                role="presentation"
                src="assets/images/icons/menu-icons/chevron.svg"></svg-icon>
      <span class="link-label">{{'shared.ShowMore' | translate}}</span>
    </a>
  </div>
  <div class="mega-menu">
    <a routerLink="/mylocations"
       routerLinkActive="active">
      <svg-icon role="presentation"
                src="assets/images/icons/menu-icons/building.svg"></svg-icon>
      <span class="link-label">
        {{'SideNav.ManageLocations' | translate}}
      </span>
    </a>

    <a *ngIf="moduleOptions?.SurveyEnable && !isSurveyCheck" 
    routerLink="/myrequests/surveyrequests"
    routerLinkActive="active">
   <svg-icon role="presentation"
             src="assets/images/icons/menu-icons/survey.svg"></svg-icon>
   <span class="link-label">
     {{'SideNav.Survey' | translate}}
   </span>
 </a>
    <a routerLink="/faq"
       routerLinkActive="active">
      <svg-icon role="presentation"
                src="assets/images/icons/menu-icons/question.svg"></svg-icon>
      <span class="link-label">{{'SideNav.FAQs' | translate}}</span>
    </a>
    <a routerLink="/myrequests/archivedrequests"
       routerLinkActive="active">
      <svg-icon role="presentation"
                src="assets/images/icons/menu-icons/archive.svg"></svg-icon>
      <span class="link-label">
        {{'SideNav.ArchivedRequests' | translate}}
      </span>
    </a>
  </div>
</div>
