import { Routes } from "@angular/router";
import { ErrorComponent } from 'src/app/modules/core/components/error/error.component';
import { AuthGuard } from './modules/core/services/auth.guard';
import { TenantService } from './modules/core/services/tenant.service';
import { AfterLoginService } from './modules/core/services/after-login.service';
import { SSOLoginComponent } from './modules/core/components/sso-login/sso-login.component';
import { AccessForbiddenComponent } from './modules/core/components/access-forbidden/access-forbidden.component';
import { RegistrationGuard } from './modules/core/services/registration.guard';
import { MainComponent } from './modules/core/components/main/main.component';
var ɵ0 = { breadcrumb: 'shared.WebRequest' }, ɵ1 = { breadcrumb: 'shared.WebRequest' }, ɵ2 = { breadcrumb: 'shared.WebRequest', needHelp: true }, ɵ3 = { breadcrumb: 'SideNav.NewRequest' }, ɵ4 = { breadcrumb: 'SideNav.ManageLocations' }, ɵ5 = { breadcrumb: 'SideNav.MyRequests' }, ɵ6 = { breadcrumb: 'SideNav.Survey' }, ɵ7 = { breadcrumb: 'SideNav.FAQs' }, ɵ8 = { breadcrumb: 'SideNav.Error' }, ɵ9 = { breadcrumb: 'SideNav.Error' }, ɵ10 = { error: 404 }, ɵ11 = { error: 404 };
var appRoutes = [
    {
        path: "",
        component: MainComponent,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            },
            {
                path: "login",
                component: SSOLoginComponent,
                canActivate: [AfterLoginService],
                data: ɵ0
            },
            {
                path: "registration",
                loadChildren: "./modules/registration/registration.module#RegistrationModule",
                canLoad: [RegistrationGuard],
                data: ɵ1
            },
            {
                path: "account",
                loadChildren: "./modules/unlockreset/unlockreset.module#UnlockResetModule",
                data: ɵ2
            }
        ]
    },
    {
        path: "settings",
        loadChildren: "./modules/account-settings/account-settings.module#AccountSettingsModule",
        canLoad: [AuthGuard]
    },
    {
        path: "newrequest",
        loadChildren: "./modules/new-request/new-request.module#NewRequestModule",
        data: ɵ3,
        canLoad: [AuthGuard]
    },
    {
        path: "mylocations",
        loadChildren: "./modules/my-locations/my-locations.module#MyLocationsModule",
        data: ɵ4,
        canLoad: [AuthGuard]
    },
    {
        path: "myrequests",
        loadChildren: "./modules/my-requests/my-requests.module#MyRequestsModule",
        data: ɵ5,
        canLoad: [AuthGuard]
    },
    {
        path: "surveyrequests",
        loadChildren: "./modules/my-requests/my-requests.module#MyRequestsModule",
        data: ɵ6,
        canLoad: [AuthGuard]
    },
    {
        path: "faq",
        loadChildren: "./modules/faq/faq.module#FaqModule",
        data: ɵ7,
        canLoad: [AuthGuard]
    },
    {
        path: 'error',
        component: ErrorComponent,
        data: ɵ8
    },
    {
        path: 'access-forbidden',
        component: AccessForbiddenComponent,
        data: ɵ9
    },
    {
        path: ':rsClientId',
        canActivate: [TenantService],
        children: [{
                path: "**", component: ErrorComponent, data: ɵ10
            }]
    },
    { path: "**", component: ErrorComponent, data: ɵ11 }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
