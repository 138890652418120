import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { MainMenuService } from '../../services/main-menu.service';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { CallUsRequestService } from '../../services/call-us-request.service';
import { WorkOrderRequestService } from 'src/app/modules/shared-request/services/work-order-request.service';
var SideNavTreeComponent = /** @class */ (function () {
    function SideNavTreeComponent(router, userService, mainMenuService, callUsRequestService, workOrderRequestService, cdr) {
        this.router = router;
        this.userService = userService;
        this.mainMenuService = mainMenuService;
        this.callUsRequestService = callUsRequestService;
        this.workOrderRequestService = workOrderRequestService;
        this.cdr = cdr;
        this.activeSection = '';
        this.WorkOrders = null;
        this.unsubscribe$ = new Subject();
    }
    SideNavTreeComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.isSurveyCheck = false;
                this.subscribeToData();
                return [2 /*return*/];
            });
        });
    };
    SideNavTreeComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    SideNavTreeComponent.prototype.subscribeToData = function () {
        var _this = this;
        this.workOrderRequestService.data$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (data) {
            _this.WorkOrders = data;
            var workorder = _this.WorkOrders.filter(function (x) { return x.SurveyButtonEnabled == true; });
            if (data.length === 0 && workorder.length === 0) {
                _this.isSurveyCheck = true;
            }
            else if (_this.WorkOrders.length > 0 && workorder.length === 0) {
                _this.isSurveyCheck = true;
            }
            else {
                _this.isSurveyCheck = false;
            }
            _this.getMenu();
            _this.cdr.detectChanges();
        });
    };
    SideNavTreeComponent.prototype.getMenu = function () {
        var _this = this;
        this.mainMenuService.getMenuItems()
            .pipe(take(1), map(function (menuItems) {
            if (_this.moduleOptions && _this.moduleOptions.SurveyEnable === false) {
                return menuItems.filter(function (item) { return item.name !== 'Survey'; });
            }
            else if (_this.isSurveyCheck == true) {
                return menuItems.filter(function (item) { return item.name !== 'Survey'; });
            }
            else {
                return menuItems;
            }
        }))
            .subscribe(function (filteredMenuItems) {
            _this.mainMenu = filteredMenuItems;
        });
    };
    SideNavTreeComponent.prototype.toggleSection = function (section) {
        if (this.activeSection !== section) {
            this.activeSection = section;
        }
        else {
            this.activeSection = '';
        }
        //console.log(this.activeSection);
    };
    Object.defineProperty(SideNavTreeComponent.prototype, "isAuthenticated", {
        get: function () {
            return this.userService.isAuthenticated();
        },
        enumerable: true,
        configurable: true
    });
    SideNavTreeComponent.prototype.logout = function () {
        this.userService.clear();
        this.router.navigateByUrl('/login');
    };
    return SideNavTreeComponent;
}());
export { SideNavTreeComponent };
