export const environment = {
  name: 'prod',
  production: true,
  version: '1.0.0.0',
  timeStamp: '04/14/2021 06:23:25 PM EDT',
  baseUrl: '../../',
  baseUrlForDev: 'http://localhost:4200/',
  baseUrlClientRequest: 'RealSuiteApps/RealSuiteWebAPIs/RealClientRequest/api/',
  baseUrlUserAccount: 'RealSuiteApps/RealSuiteWebAPIs/RealUserAccount/api/',
  baseUrlClientLogo: 'RealSuite/',
  baseUrlWebApi: 'RealSuiteApps/RealHelp.WebApi/api/',
  baseUrlRealSuiteUserAuth: 'RealSuitePublic/UserAuth/',
  baseUrlWebAssist: 'realsuiteclientapps/bgisassist/',
  baseUrlRealSuite: 'RealSuiteApps/RealSuite/'

};
