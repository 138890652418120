<div
    class="wrapper"
    (window:resize)="onResize()">
    <div id="skiptocontent">
        <a href="#maincontent">
            {{'layout.SkipToMainContent' | translate}}
        </a>
    </div>
    <app-side-nav-tree
    class="sidebar"
    [moduleOptions]="moduleOptions"
    *ngIf="!(responsiveService.state$ | async) && isDataReady">
</app-side-nav-tree>
<app-side-nav-tree
class="sidebar"
[moduleOptions]="moduleOptions"
*ngIf="!(responsiveService.state$ | async) && !isDataReady">
</app-side-nav-tree>
    <div class="content">
        <header>
            <h1>{{title}}<sup class="trademark" *ngIf="localBrandVariables.showTrademark && title===localBrandVariables.brandName">{{('layout.Trademark' | translate)}}</sup></h1>
            <div class="header-bar">
              <div>
                <div class="welcome"
                     *ngIf="isAuthenticated | async; else nonRegisteredUser">
                  <span class="welcome-text">{{'layout.Welcome' | translate}} {{(currentUser$ | async)?.firstName}}!</span>
                  <button mat-icon-button
                          [matMenuTriggerFor]="menu"
                          [attr.aria-label]="'layout.ProfileMenu' | translate"
                          class="ml-3">
                    <svg-icon class="avatar-default"
                              role="presentation"
                              focusable="false"
                              src="assets/images/icons/user-avatar.svg">
                    </svg-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item
                            routerLinkActive="active"
                            routerLink="/settings/myprofile">
                      <svg-icon class="menu-item-icon"
                                role="presentation"
                                focusable="false"
                                src="assets/images/icons/dropdown-menu/profile.svg">
                      </svg-icon>
                      {{'SideNav.MyProfile' | translate}}
                    </button>
                    <button *ngIf="!isSSOFederated"
                            mat-menu-item
                            routerLinkActive="active"
                            routerLink="/settings/changepwd">
                      <svg-icon class="menu-item-icon"
                                role="presentation"
                                focusable="false"
                                src="assets/images/icons/dropdown-menu/lock.svg">
                      </svg-icon>
                      {{'SideNav.ChangePassword' | translate}}
                    </button>
                    <button mat-menu-item
                            routerLinkActive="active"
                            routerLink="/settings/notifications">
                      <svg-icon class="menu-item-icon"
                                role="presentation"
                                focusable="false"
                                src="assets/images/icons/dropdown-menu/bell.svg">
                      </svg-icon>
                      {{'SideNav.Notifications' | translate}}
                    </button>
                    <a mat-menu-item
                       href="{{ssoLogoutUrl}}">
                      <svg-icon class="menu-item-icon"
                                role="presentation"
                                focusable="false"
                                src="assets/images/icons/dropdown-menu/exit.svg">
                      </svg-icon>
                      <span>{{'SideNav.Logout' | translate}}</span>
                    </a>
                  </mat-menu>
                </div>
                <ng-template #nonRegisteredUser>
                  <a mat-menu-item *ngIf="hasUserName"
                     href="{{ssoLogoutUrl}}" class="logout-icon"
                     [hidden]="true">
                    <svg-icon class="menu-item-icon"
                              role="presentation"
                              focusable="false"
                              src="assets/images/icons/dropdown-menu/exit.svg">
                    </svg-icon>
                    <span>{{'SideNav.Logout' | translate}}</span>
                  </a>
                </ng-template>
              </div>
              <div>
              <app-clientlogo></app-clientlogo>
              </div>
            </div>
        </header>
        <app-breadcrumb></app-breadcrumb>
        <main
            role="main"
            id="maincontent">
            <section
                class="main-container"
                aria-live="polite">
                <router-outlet></router-outlet>
            </section>

        </main>
        <app-footer class="footer-container"></app-footer>
    </div>
    <app-bottom-nav 
    [moduleOptions]="moduleOptions" 
    *ngIf="!!(responsiveService.state$ | async) && !virtualKeyboardVisible && isDataReady"></app-bottom-nav>
</div>
