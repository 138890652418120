import * as tslib_1 from "tslib";
import { ChangeDetectorRef } from '@angular/core';
import { UserdatasharingService } from 'src/app/modules/core/services/userdatasharing.service';
import { CallUsRequestService } from 'src/app/modules/core/services/call-us-request.service';
import { WorkOrderRequestService } from 'src/app/modules/shared-request/services/work-order-request.service';
var BottomNavComponent = /** @class */ (function () {
    function BottomNavComponent(userService, callUsRequestService, workOrderRequestService, cdr) {
        this.userService = userService;
        this.callUsRequestService = callUsRequestService;
        this.workOrderRequestService = workOrderRequestService;
        this.cdr = cdr;
        this.visible = false;
        this.WorkOrders = null;
    }
    BottomNavComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.isSurveyCheck = false;
                this.subscribeToData();
                return [2 /*return*/];
            });
        });
    };
    BottomNavComponent.prototype.subscribeToData = function () {
        var _this = this;
        this.workOrderRequestService.data$.subscribe(function (data) {
            _this.WorkOrders = data;
            var workorder = _this.WorkOrders.filter(function (x) { return x.SurveyButtonEnabled == true; });
            if (data.length == 0 && workorder.length == 0) {
                _this.isSurveyCheck = true;
                _this.cdr.detectChanges();
            }
            else if (_this.WorkOrders.length > 0 && workorder.length == 0) {
                _this.isSurveyCheck = true;
                _this.cdr.detectChanges();
            }
            else {
                _this.isSurveyCheck = false;
                _this.cdr.detectChanges();
            }
        });
    };
    Object.defineProperty(BottomNavComponent.prototype, "isAuthenticated", {
        get: function () {
            return this.userService.isAuthenticated();
        },
        enumerable: true,
        configurable: true
    });
    return BottomNavComponent;
}());
export { BottomNavComponent };
